import React from 'react'
import Navbar from '../Components/commonforboth/Navbar'
import Banner from '../Components/Common/Banner'
import img2 from "../assets/images/slider-2.jpg";
import img3 from "../assets/images/slider-3.jpg";
import img4 from "../assets/images/slider-4.jpg";
import img5 from "../assets/images/slider-5.jpg";
import img6 from "../assets/images/slider-6.jpg";
import img7 from "../assets/images/About/img-4.jpg";
import img8 from "../assets/images/About/img-5.jpg";
import img9 from "../assets/images/About/img-6.jpg";
import img10 from "../assets/images/About/img-7.jpg";
import img11 from "../assets/images/About/img-1.jpg";
import img12 from "../assets/images/about-img.webp";
import img13 from "../assets/images/img1.wsimg.png";
import Footer from '../Components/commonforboth/Footer';
import video from "../assets/images/About/webm-video.mp4";
import video2 from "../assets/images/About/gallery-video.mp4";

const Gallery = () => {
    const images = [
        { img: img2 },
        { img: img3 },
        { img: img4 },
        { img: img5 },
        { img: img6 },
        { img: img7 },
        { img: img8 },
        { img: img9 },
        { img: img10 },
        { img: img11 },
        { img: img12 },
        { img: img13 },
    ]
    return (
        <div>
            <Navbar />
            <Banner pageName={"Gallery"} pageTitle={"Gallery"} />
            <section className="py-4 py-mb-3 ">
                <div className="container py-5">
                    <div>
                        <div className="heading pb-4">
                            <h2 className="text-uppercase fw-bold text-center">
                                <span
                                    className="border-pink pb-2"
                                    style={{ boxSizing: "border-box", lineHeight: "2.5", color: "#2c8b49" }}
                                >
                                    Gallery Section
                                </span>
                            </h2>
                        </div>
                    </div>
                    <div className='row '>
                        {images?.map((obj) => {
                            return (
                                <div className='col-lg-3 mb-4 gallery-section'>
                                    <figure>
                                        <img src={obj.img} height='250' className='gallery-img w-100 object-fit-cover' alt='Gallery Image' />
                                    </figure>
                                </div>
                            )
                        })}
                        <div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-lg-6'>
                            <div>
                                <video width="100%" controls>
                                    <source src={video} type="video/webm" />
                                    Sorry, your browser doesn't support videos.
                                </video>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div>
                                <video width="100%" height='320' className='object-fit-cover video-heigth' controls>
                                    <source src={video2}  type="video/webm" />
                                    Sorry, your browser doesn't support videos.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Gallery