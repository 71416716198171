import React, { useEffect, useState } from "react";
import "../assets/Css/Donate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../Components/commonforboth/Footer";
import Navbar from "../Components/commonforboth/Navbar";
import image1 from "../assets/images/img1.wsimg.png";
import Banner from "../Components/Common/Banner";
import img1 from "../assets/images/Donate/img-1.jpg";
import img2 from "../assets/images/Donate/img-2.jpg";
import img3 from "../assets/images/Donate/img-3.png";
import img4 from "../assets/images/Donate/img-4.jpg";
import Loader from "../Components/Common/Loader";

const Donate = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    AOS.init({
      offset: 100, // offset (in px) from the original trigger point
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease-out",
      once: false,
    });
  }, []);
  return (
    <div id="Donate">
      {loading && <Loader />}
      <Navbar />
      <Banner pageName={"Donate"} pageTitle={"Donate"} />

      <section className="py-5 py-mb-4 px-mb-4 bg-light ">
        <div className="container py-3">
          <div className="row">
            <div
              className="col-sm-6"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="card px-5 px-mb-0">
                <div>
                  <figure>
                    <img src={image1} alt="image1" className="img-fluid" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-sm-6 pt-mb-5">
              <div className=" ps-mb-0 ps-4">
                <h2 className=" fw-bold">
                  <span
                    className="border-pink pb-2"
                    style={{ boxSizing: "border-box", lineHeight: "2", color: "#2c8b49" }}
                  >
                    Need 100 Donors Donating £1,000 Each
                  </span>
                </h2>
                <p className="text-muted ">
                  <b>Account Name: </b>
                  <span className=""> IQRA WELFARE ASSOCIATION <br /> LLOYD'S BANK</span>
                </p>
                <p className="text-muted ">
                  <b>Sort Code: </b>
                  <span className=""> 30-99-50</span>
                </p>
                <p className="text-muted ">
                  <b>Account No: </b>
                  <span className="">25446863 </span>
                </p>
                <p className="text-muted ">
                  <b>Ref: </b>
                  <span className="">Website</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 py-mb-4 px-mb-4 bg-section-2">
        <div className="container py-3">
          <div className="row">
            <div className="col-sm-6 order-mb-2 pt-mb-5">
              <div className="">
                <h2 className=" fw-bold">
                  <span
                    className="border-pink pb-2"
                    style={{ boxSizing: "border-box", lineHeight: "2", color: "#2c8b49" }}
                  >
                    Activities as Follows
                  </span>
                </h2>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Building of Iqra Hafizi Madrasah & Orphanage Center
                </p>
                <p className="text-muted">
                  <i className="bi bi-arrow-right"></i> Huffas Students in Class
                </p>
                <p className="text-muted">
                  <i className="bi bi-arrow-right"></i> Dinning Room , Study Room, Wudu Facilities
                </p>
                <p className="text-muted">
                  <i className="bi bi-arrow-right"></i> Pure Water Facilities for Students & Neighbors
                </p>
                <p className="text-muted">
                  <i className="bi bi-arrow-right"></i> Pure Water Facilities for Students & Neighbors
                </p>
              </div>
            </div>
            <div
              className="col-sm-6 px-d-5 px-mb-0 order-mb-1"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div className="card px-5 px-mb-0">
                <img src={img3} alt="image1" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 py-mb-4 px-mb-4 bg-light ">
        <div className="container py-3">
          <div className="row">
            <div
              className="col-sm-6 px-d-5 px-mb-0 "
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="card px-5 px-mb-0">
                <img src={img4} alt="image1" className="img-fluid" />
              </div>
            </div>
            <div className="col-sm-6 pt-mb-5">
              <div className="ps-mb-0">
                <h2 className="fw-bold">
                  <span
                    className="border-pink  pb-2"
                    style={{ boxSizing: "border-box", lineHeight: "2", color: "#2c8b49" }}
                  >
                    Support Our Other Projects
                  </span>
                </h2>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Warm Blankets for huffaz Students
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Flood Relief in Bangladesh
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Iqra Doctors Teams
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Chairman monitored windows and yateem families
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Food for widows
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Your Qurbani is for their Eid meals
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Sponsors A Widow Families - £ 25 per month
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> House For Widow  1 House - £ 2000
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> House For Widow  1 Portion - £ 100
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 py-mb-4  px-mb-4 bg-section-2">
        <div className="container py-3">
          <div className="row">
            <div className="col-sm-6 order-mb-2 pt-mb-5 pt-mb-5">
              <div className="">
                <h2 className=" fw-bold">
                  <span
                    className="border-pink pb-2"
                    style={{ boxSizing: "border-box", lineHeight: "2", color: "#2c8b49" }}
                  >
                    Activities as Follows
                  </span>
                </h2>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Iqra Quran Academy
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Iqra Quran Class
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Widow & Yateem House
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Chairman monitored windows and yateem families
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Flood Relief in Bangladesh
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Distribution Food packets
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> Sponsors A Widow Families - £ 25 per month
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> House For Widow  1 House - £ 2000
                </p>
                <p className="text-muted ">
                  <i className="bi bi-arrow-right"></i> House For Widow  1 Portion - £ 100
                </p>
              </div>
            </div>
            <div
              className="col-sm-6 px-d-5 px-mb-0 order-mb-1"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div className="card px-5 px-mb-0">
                <img src={img1} alt="image1" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 py-mb-4 px-mb-4 bg-light ">
        <div className="container py-3">
          <div className="row">
            <div
              className="col-sm-6 px-d-5 px-mb-0"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="card px-5 px-mb-0">
                <img src={img2} alt="image1" className="img-fluid" />
              </div>
            </div>
            <div className="col-sm-6">
              <div className=" ps-mb-0 ps-4">
                <h2 className=" fw-bold">
                  <span
                    className="border-pink pb-2"
                    style={{ boxSizing: "border-box", lineHeight: "2", color: "#2c8b49" }}
                  >
                   Activities as Follows
                  </span>
                </h2>
                <p className="text-muted ">
                  The IQRA Doctors team is extending their compassion and support this Ramadan season by distributing food packs to widows and orphaned families, ensuring they have nourishment during this holy month. Additionally, they're providing warm blankets to Hafiz students, acknowledging their dedication to their studies and providing comfort in the colder months. Understanding the significance of Eid, the team is allocating Qurbani donations to ensure these families have festive meals to celebrate the occasion. Moreover, they're instituting a monthly stipend for widows, offering sustained assistance for their essential needs beyond Ramadan.
                </p>

              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer />
    </div>
  );
};

export default Donate;
