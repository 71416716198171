import React from "react";
import "../../assets/Css/Footer.css";
import { Link } from "react-router-dom";
import { Config } from "../../Config";

const Footer = () => {
  return (
    <div className="">
      <div className="">
        <footer
          className="text-center text-lg-start text-dark"
          style={{ backgroundColor: "#2c8b49" }}
        >
          <div className="text-center p-4 ">
            <div className="d-flex flex-column justify-content-center">
              <div className="footer-text">
                Copyright © 2024 IQRA WELFARE ASSOCIATION - charity No: 1197747
                - All Rights Reserved.
              </div>
              <div className="footer-text ">
                Design and Developed by{" "}
                <a
                  href="https://www.zithas.co.uk"
                  target="_blank"
                  className="text-decoration-none a"
                >
                  {" "}
                  zithas Technologies{" "}
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
