import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/index";
import { Config } from "./Config";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Donate from "./pages/Donate";
import "bootstrap/dist/css/bootstrap.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Gallery from "./pages/Gallery";


function App() {
  return (
    <div className="">
      <Routes>
        <Route path={`${Config.appUrl}`} element={<Home />} />
        <Route path={`${Config.appUrl}/donate`} element={<Donate />} />
        <Route path={`${Config.appUrl}/about`} element={<About />} />
        <Route path={`${Config.appUrl}/contact`} element={<Contact />} />
        <Route path={`${Config.appUrl}/gallery`} element={<Gallery />} />
      </Routes>
    </div>
  );
}

export default App;
