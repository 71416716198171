import React from "react";
import banner1 from "../../assets/images/main-banner.jpg";
import "../../assets/Css/Carausel.css";

const Carausel = () => {
  return (
    <>
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={banner1} className="d-block w-100 mobile-back" alt="..." />
            <div className="bd-banner-text ">
              <div className="container over" data-aos="fade-left"
                 data-aos-delay="100">
                <h3 className="">Celebrate the Act of Giving</h3>
                <p className="">Charity does not decrease your wealth!</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Carausel;
