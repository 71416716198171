import React, { useEffect, useState } from "react";
import Footer from "../Components/commonforboth/Footer";
import Navbar from "../Components/commonforboth/Navbar";
import Banner from "../Components/Common/Banner";
import "../assets/Css/Contact.css";
import Loader from "../Components/Common/Loader";
import swal from "sweetalert";
import axios from "axios";
import { Config } from "../Config";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState({
    fname: "",
    lname: "",
    email: "",
    message: "",
  });
  const validateForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = "First name is required";
    }


    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }

    if (!message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true)
      const formData = new FormData()
      formData.append('name', name)
      formData.append('email', email)
      formData.append('message', message)
      try {
        const response = await axios(
          `${Config.apiUrl}/connect`,
          {
            method: "POST",
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        swal({
          text: "Your message has been sent successfully!",
          icon: "success",
        });
        setLoading(false)
        setName('')
        setEmail('')
        setMessage('')
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
  };
  return (
    <div id="contact">
      {loading && <Loader />}
      <Navbar />
      <Banner pageName={"Contact Us"} pageTitle={"Contact Us"} />
      <section className="py-5 my-3 pb-mb-0 px-mb-3">
        <div className="container  py-mb-0">
          <div className="row">
            <div className="col-md-6 bg-light-pink py-3 shadow">
              <div className="form-section py-3">
                <div className="px-5 px-mb-0">
                  <div className="py-3">
                    <h2 className="">GET IN TOUCH</h2>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="row ">
                      <div className="col-md-12 pb-3">
                        <div className="form-group">
                          <label htmlFor="fname" className="">
                            Full Name
                          </label>
                          <input
                            type="text"
                            value={name}
                            required
                            onChange={(e) => setName(e.target.value)}
                            className="form-control"
                            id="fname"
                            placeholder="Enter Your Full Name"
                          />
                          {errors.fname && (
                            <span className="text-danger">{errors.fname}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                    <div className="form-group pb-3">
                      <label htmlFor="exampleInputEmail1" className="">
                        Email address
                      </label>
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={() => setErrors("")}
                        required
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter email"
                      />
                      {errors.email && (
                        <span className="text-danger">{errors.email}</span>
                      )}
                    </div>

                    <div className="form-group pb-3">
                      <label htmlFor="help" className="pb-2">
                        How can we help you?
                      </label>
                      <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        name="help"
                        id=""
                        rows="3"
                        className="w-100 form-control"
                        placeholder="Please Enter your Message Here"
                      ></textarea>
                    </div>

                    <div className="d-flex pt-3">
                      <button className="btn button w-50">submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-6 py-mb-5">
              <div className=" ps-5 ps-mb-0">
                <div className="iqraemail">
                  <div className="">
                    <h1 className="py-3    mb-0">
                      IQRA WELFARE ASSOCIATION
                    </h1>
                  </div>
                  <p className=" text-mb-left">
                    With all of us working together, we can accomplish great
                    things. To volunteer or contribute to our fundraiser, send us
                    a message. We will get back to you soon.
                  </p>

                  <div className="">
                    <a
                      className="nav-link fs-4 px-mb-0 text-mb-left email text-pink"
                      href="mailto:infoiqrawelfareuk@gmail.com"
                    >
                      <i className="bi bi-envelope-arrow-up me-2" ></i>infoiqrawelfareuk@gmail.com
                    </a>
                    <a
                      className="nav-link fs-4 px-mb-0 text-mb-left email text-pink"
                    >
                      Charity Number : 1197747
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Contact;
