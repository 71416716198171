import React from "react";
import Slider from "react-slick";
import "../../assets/Css/Slider.css";
import img1 from "../../assets/images/slider-img.jpg";
import img2 from "../../assets/images/slider-2.jpg";
import img3 from "../../assets/images/slider-3.jpg";
import img4 from "../../assets/images/slider-4.jpg";
import img5 from "../../assets/images/slider-5.jpg";
import img6 from "../../assets/images/slider-6.jpg";
import img7 from "../../assets/images/About/img-4.jpg";
import img8 from "../../assets/images/About/img-5.jpg";
import img9 from "../../assets/images/About/img-6.jpg";
import img10 from "../../assets/images/About/img-7.jpg";


const ImageSlider = () => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="py-5" id="slider">
      <Slider {...settings}>
        <div className="service-card-1">
          <img src={img8} className="img-fluid service-img" alt="img6" />
          <div className="service-data"></div>
        </div>
        <div className="service-card-1">
          <img src={img9} className="img-fluid service-img" alt="img6" />
          <div className="service-data"></div>
        </div>
        <div className="service-card-1">
          <img src={img10} className="img-fluid service-img" alt="img6" />
          <div className="service-data"></div>
        </div>
        <div className="service-card-1">
          <img src={img1} className="img-fluid service-img" alt="img1" />
          <div className="service-data"></div>
        </div>
        <div className="service-card-1">
          <img src={img2} className="img-fluid service-img" alt="img1" />
          <div className="service-data"></div>
        </div>
        <div className="service-card-1">
          <img src={img3} className="img-fluid service-img" alt="img1" />
          <div className="service-data"></div>
        </div>
        <div className="service-card-1">
          <img src={img4} className="img-fluid service-img" alt="img1" />
          <div className="service-data"></div>
        </div>
        <div className="service-card-1">
          <img src={img5} className="img-fluid service-img" alt="img1" />
          <div className="service-data"></div>
        </div>

        <div className="service-card-1">
          <img src={img6} className="img-fluid service-img" alt="img6" />
          <div className="service-data"></div>
        </div>
        <div className="service-card-1">
          <img src={img7} className="img-fluid service-img" alt="img6" />
          <div className="service-data"></div>
        </div>

      </Slider>
    </div>
  );
};

export default ImageSlider;
