import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.jpg";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../../assets/Css/Nav.css";
import { Config } from "../../Config";

const Navbar = () => {
  const navHome = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  const [stickyNavbar, setStickyNavbar] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  });

  function openNav() {
    document.getElementById("mySidenav").style.width = "100%";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  return (
    <>
      <nav className={`navbar navbar-expand-lg bg-link-pink z-3  ${stickyNavbar ? "skicky-navbar" : ""}`}>
        <div className="container px-0">
          <div className="">
            <Link className="" aria-current="page" to={`${Config.appUrl}/`}>
              <img
                className="navbar-brand img-fluid py-0"
                src={logo}
                alt="logo"
              />
            </Link>
          </div>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => openNav()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
              <li className="nav-item">
                <Link
                  className={`nav-link  ${navHome.pathname == Config.appUrl ? "" : ""
                    }`}
                  to={`${Config.appUrl}/`}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link " to={`${Config.appUrl}/donate`}>
                  Donate
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link " to={`${Config.appUrl}/about`}>
                  About Us
                </NavLink>
              </li>{" "}
              <li className="nav-item">
                <NavLink className="nav-link " to={`${Config.appUrl}/gallery`}>
                  Gallery
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link " to={`${Config.appUrl}/contact`}>
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div id="mySidenav" class="sidenav">
        <a
          href="javascript:void(0)"
          class="closebtn"
          onClick={() => closeNav()}
        >
          &times;
        </a>
        <Link
          className={`nav-link  ${navHome.pathname == Config.appUrl ? "" : ""
            }`}
          to={`${Config.appUrl}`}
        >
          Home
        </Link>
        <NavLink className="nav-link " to={`${Config.appUrl}/donate`}>
          Donate
        </NavLink>
        <NavLink className="nav-link " to={`${Config.appUrl}/about`}>
          About Us
        </NavLink>
        <NavLink className="nav-link " to={`${Config.appUrl}/gallery`}>
          Gallery
        </NavLink>
        <NavLink className="nav-link " to={`${Config.appUrl}/contact`}>
          Contact Us
        </NavLink>
      </div>

    </>
  );
};

export default Navbar;
