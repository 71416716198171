import React from "react";
import { Config } from "../../Config";
import { Link } from "react-router-dom";
import "../../assets/Css/Common.css";
const Banner = ({ pageTitle, pageName }) => {
  const imgUrl =
    "https://media.istockphoto.com/id/92015289/photo/charity.jpg?b=1&s=170667a&w=0&k=20&c=af8yhlLMp0kyQmkh7XOEhX4ezAqPPaNrM9sdSYwa0b0=";
  return (
    <div id="banner">
      <section
        className="page-header padding-box"
        style={{
          backgroundImage: `linear-gradient(#ffffff56, #00000091 0%), url("${imgUrl}")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container px-mb-4">
          <div className="text-white">
            <h1 className="fw-bold display-5">{pageTitle} </h1>
            <p className="mt-4 header-path-link">
              <Link
                to={`${Config.appUrl}`}
                className="text-pink fw-bold text-decoration-none"
              >
                {" "}
                Home
              </Link>
              <i className="bi bi-arrow-right mx-3"></i>
              {pageName}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
