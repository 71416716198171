import React, { useEffect, useRef, useState } from "react";
import Footer from "../Components/commonforboth/Footer";
import Navbar from "../Components/commonforboth/Navbar";
import Banner from "../Components/Common/Banner";
import img1 from "../assets/images/About/img-1.jpg";
import img2 from "../assets/images/About/img-2.jpg";
import img3 from "../assets/images/About/img-3.jpg";
import video from "../assets/images/About/webm-video.mp4";
import "../assets/Css/About.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "../Components/Common/Loader";

const About = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    AOS.init({
      offset: 100, // offset (in px) from the original trigger point
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease-out",
      once: false,
    });
  }, []);

  return (
    <div id="About">
      {loading && <Loader />}
      <Navbar />
      <Banner pageName={"About Us"} pageTitle={"About Us"} />
      <div id="ABOUT">
        <section className="py-5 my-2 py-mb-4 bg-light px-mb-3 ">
          <div className="container py-4">
            <div className="row">
              <div
                className="col-md-6"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="hover12">
                  <figure>
                    <img src={img1} alt="about" className="img-fluid" />
                  </figure>
                </div>
              </div>
              <div className="col-md-6 pt-mb-5">
                <div>
                  <div className="heading pb-3">
                    <h2 className="fw-bold">
                      <span
                        className=" border-pink pb-2"
                        style={{ boxSizing: "border-box", lineHeight: "2", color: "#2c8b49" }}
                      >
                        Iqra Hafizul Quran Academy & Orphanage Center
                      </span>
                    </h2>
                  </div>
                  <div>
                    <p className="mb-0">
                      IQRA Looking after widows and orphans for their education and welfare. Helping many needy to have shelters, food, clothing, water wells, flood relief to make their lives a little better. We aim to do as much as possible with your generous donations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 my-2 py-mb-4 px-mb-3 bg-section-2">
          <div className="container py-3">
            <div className="row ">
              <div className="col-md-6 order-mb-2 ps-2 pt-mb-5">
                <div className="">
                  <div className="heading pb-3">
                    <h2 className="fw-bold">
                      <span
                        className="border-pink pb-2"
                        style={{ boxSizing: "border-box", lineHeight: "2", color: "#2c8b49" }}
                      >
                        Iqra Hafizi Madarasah and Orphanage Center
                      </span>
                    </h2>
                  </div>
                  <div>
                    <p className="text-muted ">
                      <i className="bi bi-arrow-right"></i> New Building for Madarasah , Dining Room ,Huffaz students in Class and Huffaz memorising Quran
                    </p>
                    <p className="text-muted ">
                      <i className="bi bi-arrow-right"></i> Donate Your Wealth for forgiveness of your beloved one
                    </p>
                    <p className="text-muted ">
                      <i className="bi bi-arrow-right"></i> "The best amongst  you is the one who learns the Quran's and teaches it - 'Sahih Al-Bukhari'"
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 order-mb-1"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <div className="px-5 px-mb-0">
                  <div className="hover12">
                    <figure>
                      <img src={img3} alt="about" className="img-fluid" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Video section  */}
        <section className="py-5 my-2 py-mb-4 bg-light px-mb-3 ">
          <div className="container py-3">
            <div className="row">
              <div className="col-md-12">
                <div className="user-1 d-flex justify-content-center  card">
                  <video width="100%" height='' controls>
                    <source src={video} type="video/webm" />
                    Sorry, your browser doesn't support videos.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="py-5 my-2 py-mb-4 bg-light px-mb-3 bg-section-2">
          <div className="container py-4">
            <div>
              <h2 className="text-center fw-bold text-mb-left py-3">
                <span
                  className="border-bottom border-2 border-success pb-2"
                  style={{ boxSizing: "border-box", lineHeight: "2" }}
                >
                  Who We Help
                </span>
              </h2>
              <p className="text-center text-mb-left">
                IQRA Looking after widows and orphans for their education and welfare. Helping many needy to have shelters, food, clothing, water wells, flood relief to make their lives a little better. We aim to do as much as possible with your generous donations.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default About;
