import React, { useEffect, useState } from "react";
import Navbar from "../../Components/commonforboth/Navbar";
import Carausel from "../../Components/commonforboth/Carausel";
import "../../assets/Css/Home.css";
import aboutimg from "../../assets/images/about-img.webp";
import qurban from "../../assets/images/home/qurban.png";
import homeimg from "../../assets/images/home/img-3.png";
import ImageSlider from "../../Components/commonforboth/ImageSlider";
import Footer from "../../Components/commonforboth/Footer";
import swal from "sweetalert";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "../../Components/Common/Loader";
import axios from "axios";
import { Config } from "../../Config";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });
  const validateForm = () => {
    const newErrors = {};
    if (!name) {
      newErrors.name = "name is required";
    }

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }

    if (!message) {
      newErrors.message = "Your Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true)
      const formData = new FormData()
      formData.append('name', name)
      formData.append('email', email)
      formData.append('message', message)
      try {
        const response = await axios(
          `${Config.apiUrl}/connect`,
          {
            method: "POST",
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        swal({
          text: "Your message has been sent successfully!",
          icon: "success",
        });
        setLoading(false)
        setName('')
        setEmail('')
        setMessage('')
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
  };

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-out",
      once: false,
    });
  }, []);
  const [email_error, setEmailError] = useState(false)

  const [subscribe, setSubscribeEmail] = useState('')
  const onhandleSubscribe = async (e) => {
    e.preventDefault();
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(subscribe)) {
      setEmailError(false)
      setLoading(true);
      const formData = new FormData()
      formData.append('email', subscribe)
      try {
        const response = await axios(
          `${Config.apiUrl}/subscribe`,
          {
            method: "POST",
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        swal({
          text: "Your message has been sent successfully!",
          icon: "success",
        });
        setLoading(false)
        setSubscribeEmail('')
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      setEmailError(true)
    }
  }
  return (
    <div id="home">
      {loading && <Loader />}
      <Navbar />
      <Carausel />

      <section className="section-aboutus py-4 px-mb-4 py-mb-3 bg-light ">
        <div className="container py-4">
          <div className="row pt-4">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="hover12">
                <figure>
                  <img src={aboutimg} alt="aboutimg" className="img-fluid" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 pt-mb-3 ">
              <div className=" px-3 px-mb-0">
                <h2 className="fw-bold py-2 mb-3">
                  <span
                    className="border-pink pb-2"
                    style={{ boxSizing: "border-box", lineHeight: "1.5", color: "#2c8b49" }}
                  >
                    A Worthy Cause
                  </span>
                </h2>
                <p className="text-muted">
                  <i class="bi bi-arrow-right"></i> IQRA Looking after widows and orphans for their education and
                  welfare. Helping many needy to have shelters, food, clothing,
                  water wells, flood relief to make their lives a little better.
                  We aim to do as much as possible with your generous donations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 px-mb-4 py-mb-3 bg-section1">
        <div className="container py-4">
          <div className="row pt-4">
            <div className="col-lg-6 pt-mb-3 order-mb-2 ">
              <div className="px-mb-0 px-3">
                <h2 className="fw-bold py-2 mb-3">
                  <span
                    className="border-pink pb-2"
                    style={{ boxSizing: "border-box", lineHeight: "1.5", color: "#2c8b49" }}
                  >
                    A Great DEED
                  </span>
                </h2>
                <p className=" text-muted">
                  QURBANI, A SACRED TRUST <br /> Book your one at Iqra Welfare
                  Association
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 order-mb-1 d-flex justify-content-end justify-content-mb-center"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div className="hover12">
                <figure>
                  <img
                    src={qurban}
                    alt="aboutimg"
                    className="img-fluid h-100 border-3"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 px-mb-4 py-mb-3 bg-light   ">
        <div className="container py-4">
          <div className="row pt-4">
            <div
              className="col-lg-6 d-flex justify-content-end justify-content-mb-center"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="hover12">
                <figure>
                  <img
                    src={homeimg}
                    alt="aboutimg"
                    className="img-fluid h-100 border-3"
                  />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 pt-mb-3 ">
              <div className="px-3 px-mb-0">
                <h2 className="fw-bold py-2 mb-3">
                  <span
                    className="border-pink pb-2 line-height-mb-3"
                    style={{ boxSizing: "border-box", lineHeight: "1.5", color: "#2c8b49" }}
                  >
                    {" "}
                    Help Us to Help the Orphans!&nbsp;
                  </span>
                </h2>
                <div className="sec-Orphans">
                  <p className=" text-muted">
                    <b>Account Name - </b>
                    IQRA WELFARE ASSOCIATION <br /> LLOYD'S BANK
                  </p>
                  <p className=" text-muted">
                    <b>Sort Code - </b>
                    30-99-50
                  </p>
                  <p className=" text-muted">
                    <b>Account No. - </b>
                    25446863
                  </p>
                  <p className=" text-muted">
                    <b>Ref: - </b>
                    Website
                  </p>
                  <p className=" text-muted">
                    <b>Registered charity No: - </b>
                    1197747
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-help py-5 ">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-7">
              <h2 className="fw-bold text-white display-5">
                Subscribe
              </h2>
              <p className="text-white">
                <b>Sign up to hear from us about our work and about other great events! </b>
              </p>
            </div>
            <div className="col-md-5">
              <form class="subscribe_form" onSubmit={onhandleSubscribe} >
                <input type="email" class="form-control" placeholder="Enter You Email .." value={subscribe}
                  onChange={(e) => {
                    setSubscribeEmail(e.target.value)
                  }}  required /><button class="btn">Subscribe</button>
                  {email_error && (
                    <span className="text-white ps-3">Enter Valid Email Address</span>
                  )}
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* image slider section */}
      <section className="py-4 px-mb-4 py-mb-3 image-slider">
        <div className="container px-mb-0">
          <ImageSlider />
        </div>
      </section>

      {/* Contactus */}

      <section className="py-4 py-mb-3 ">
        <div className="container py-5">
          <div>
            <div className="heading pb-4">
              <h2 className="text-uppercase fw-bold text-center">
                <span
                  className="border-pink pb-2"
                  style={{ boxSizing: "border-box", lineHeight: "2.5", color: "#2c8b49" }}
                >
                  Contact Us
                </span>
              </h2>
            </div>
          </div>
          <div className="row pt-4 pt-mb-0">
            <div className="col-lg-6 ">
              <div className="iqraemail">
                <div className="">
                  <h1 className="py-3    mb-0">
                    IQRA WELFARE ASSOCIATION
                  </h1>
                </div>
                <p className=" text-mb-left">
                  With all of us working together, we can accomplish great
                  things. To volunteer or contribute to our fundraiser, send us
                  a message. We will get back to you soon.
                </p>

                <div className="">
                  <a
                    className="nav-link fs-4 px-mb-0 text-mb-left email text-pink"
                    href="mailto:infoiqrawelfareuk@gmail.com"
                  >
                    <i className="bi bi-envelope-arrow-up me-3" ></i>infoiqrawelfareuk@gmail.com
                  </a>
                  <a
                    className="nav-link fs-4 px-mb-0 text-mb-left email text-pink"
                  >
                    Charity Number : 1197747
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-5 px-mb-2 pt-mb-4 ">
              <div className="card px-4 shadow bg-light-pink border-0">
                <form className="p-3 px-mb-0" onSubmit={handleSubmit}>
                  <div className="form-outline mb-3">
                    <label
                      className="form-label"
                      htmlFor="form4Example1"
                    ></label>
                    <input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      onFocus={() => setErrors("")}
                      id="form4Example1"
                      className="form-control"
                      placeholder="Enter Your Name"
                    />

                    {errors.name && (
                      <span className="text-danger">{errors.name}</span>
                    )}
                  </div>

                  <div className="form-outline ">
                    <input
                      required
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onFocus={() => setErrors("")}
                      id="form4Example2"
                      className="form-control"
                      placeholder="Enter Your Email address"
                    />
                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                    <label
                      className="form-label"
                      htmlFor="form4Example2"
                    ></label>
                  </div>

                  <div className="form-outline">
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                      className="form-control"
                      id="form4Example3"
                      rows="4"
                      placeholder="Please enter your message here"
                    ></textarea>
                    {errors.message && (
                      <span className="text-danger">{errors.message}</span>
                    )}
                    <label
                      className="form-label"
                      htmlFor="form4Example3"
                    ></label>
                  </div>

                  <div className="">
                    <button
                      data-mdb-ripple-init
                      type="submit"
                      className="btn  button w-50 mb-4 w-50"

                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Index;
