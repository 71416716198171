import React from "react";
import "../../assets/Css/Loader.css";

const Loader = () => {
  return (
    <div>
      <div className="loader">
        <div className="loader-inner ball-pulse-sync">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
